import React from "react"

import SEO from "../components/seo"
// import Layout from '../layouts/findep'
import LayoutsComponent from '../layouts/LayoutsComponent';
import JumioVerifica from '../components/pages/JumioVerifica'

const JumioVerify = ({ pageContext, location }) => (
  <LayoutsComponent
    layout={pageContext.layout}
    broker={pageContext.broker}
    pageContext={pageContext}
    location={location}
    companyName={pageContext.company}
    gradient
    render={() => (
      <>
        <SEO title={pageContext.title} />
        <JumioVerifica pageContext={pageContext} />
      </>
    )}
  />
)

export default JumioVerify